<template>
  <CContainer id="activeUser">
    <LoginFormComponent
      :formType="isExpired==true? 'EXPIRED_CONFIRM_IP_LINK' : 'CONFIRM_NEW_IP'"
      :formTitle="isExpired==true ? 'Link expired' : ''"
    />
  </CContainer>
</template>

<script>
import _ from 'lodash';
import LoginFormComponent from '@/components/LoginFormComponent';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';
import { env } from '@/constants';

export default {
  name: 'ConfirmNewIp',
  data() {
    return {
      themeName: env.theme.name,
      token: '',
      isExpired: false,
    };
  },
  async mounted() {
    const token = _.trim(this.$route.query.token);
    this.token = token;
    const resultTokens = await this.$http.get(endpoints.checkToken(this.token));

    if (resultTokens.data.token_sts !== 'VALID') {
      this.isExpired = true;
    } else {
      this.confirmToken();
    }
  },
  components: { LoginFormComponent },
  methods: {
    async confirmToken() {
      try {
        const params = {
          verify_token: this.token,
        };
        const result = await this.$http.post(endpoints.confirmIp, params);

        if (result) {
          this.$notify({
            group: 'notifications',
            type: 'success',
            title: 'Success',
            text: 'Your IP has been confirmed!',
          });

          this.toLogin();
        }
      } catch (err) {
        if (err.code === 'TOKEN_EXPIRED') {
          this.isExpired = true;
          return;
        }

        this.$notify({
          group: 'notifications',
          type: 'error',
          title: 'Active',
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    toLogin() {
      this.$router.push('/login');
    },
  },
};
</script>

<style lang="scss">
.confirm-ip-container {

}
</style>
